import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class UeberSENIOinfo24 extends Component {

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section className="container content mt-5">
          
          <h1 className="col-12 text-center text-primary">Über SENIOinfo24</h1>

          <h2 className="mt-5">Über uns</h2>
          <p>
            Jeder kann im Alter, durch Unfall oder Krankheit auf Hilfe im Alltag angewiesen sein. Natürlich möchten ältere und 
            pflegebedürftige Menschen so lange wie möglich in der häuslichen, familiären und gewohnten Umgebung verbleiben. 
            Auf Ehepartnern, Kindern oder Bekannten lastet diesbezüglich häufig ein immenser Druck. Die Pflege von Angehörigen ist 
            zeitaufwendig, kraftraubend und bedeutet stets eine große Verantwortung.
          </p>
          <p>
            Die Organisation des Lebensumfeldes bei Pflege- und Hilfebedürftigkeit stellt eine große Herausforderung dar. Einen Überblick 
            der Möglichkeiten und Kosten der geeigneten Hilfen, Dienstleistungen und Betreuungsformen ist für Laien kaum möglich.
          </p>
          <p>
            Die Versorgung pflegebedürftiger Menschen ist eines der zentralen Zukunftsthemen unserer Gesellschaft - Schon heute ist jeder 
            vierte Deutsche über 60 Jahre alt. Nach Angaben des statistischen Bundesamtes gelten hierzulande über 2,3 Millionen Menschen 
            als pflegebedürftig im Sinne des Pflegeversicherungsgesetzes (SGB XI). Mehr als zwei Drittel von Ihnen werden zu Hause versorgt.
          </p>
          <p>
            Den Familien bei der Bewältigung aller Aufgaben zu helfen und Ihnen während der anspruchsvollen Zeit zur Seite zur stehen ist 
            das Anliegen von SENIOinfo24
          </p>

          <h2 className="mt-5">Plötzlich pflegebedürftig – was tun?</h2>
          <p className="text-muted">
            Pflegebedürftig sind Personen, die gesundheitlich bedingte Beeinträchtigungen der Selbständigkeit oder der Fähigkeiten aufweisen 
            und deshalb der Hilfe durch andere bedürfen. Es muss sich um Personen handeln, die körperliche, kognitive oder psychische 
            Beeinträchtigungen oder gesundheitlich bedingte Belastungen oder Anforderungen nicht selbständig kompensieren oder bewältigen können. 
            Die Pflegebedürftigkeit muss auf Dauer, voraussichtlich für mindestens sechs Monate, und mit mindestens der in § 15 festgelegten 
            Schwere bestehen.
          </p>

          <p>
            Wenn ein Angehöriger plötzlich pflegebedürftig wird und nicht in ein Pflegeheim möchte oder kann, muss schnell eine geeignete 
            Lösung gefunden werden.<br/>
            Einen Anspruch auf qualifizierte Beratung haben nicht nur Pflegebedürftige, sondern auch deren Angehörigen. Sie können eine 
            umfassende Beratung durch die Pflegeberaterinnen und -berater ihrer Kranken-/Pflegekasse erhalten. Für privat Versicherte wird die 
            Pflegeberatung durch das Unternehmen „COMPASS Private Pflegeberatung“ angeboten.
          </p>

          <p>Hier geht es um Themen wie:</p>
          <ul>
            <li>genaue Ermittlung des Hilfebedarfs und deren Finanzierungsmöglichkeiten</li>
            <li>Betreuung im eigenen Wohnumfeld</li>
            <li>Organisation ergänzender Hilfen - Haushaltshilfen, Fahrdienste, Hilfe bei Einkauf, Gartenarbeiten, Tierbetreuung und Begleitung zu Arztbesuchen</li>
            <li>Büroarbeiten, Schriftwechsel mit Behörden</li>
            <li>ggf. Beantragung von Pflegegrad und weiteren Leistungen der Pflegeversicherung</li>
            <li>Auswahl von ambulanten Pflegediensten</li>
            <li>Planung von barrierefreien Wohnraumanpassungen und deren Umsetzung</li>
            <li>Beantragung geeigneter Hilfsmitte</li>
          </ul>

        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default UeberSENIOinfo24;
