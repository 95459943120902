import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer className="container-fluid bg-gradient-primary mt-5 pt-5 pb-3">
        <div className="container-sm">
          <div className="row">
            <div className="col-12 col-md-3 text-center">
              <img src="/images/senioinfo24-logo-schwarz-weiss-280x104.png" alt="SENIOinfo24 Logo klein | 24 Stunden Betreuung" className="img-fluid" />
            </div>
            <div className="col-4 mt-3 col-md-2 mt-md-0 text-white offset-md-1">
              <img src="/images/partner-der-senioren-240x240.png" className="img-fluid" alt="SENIOinfo24 Partner-Siegel Partner der Senioren" />
            </div>
            <div className="col-4 mt-3 col-md-2 mt-md-0 text-white border-left border-info">
              <h3>Partner</h3>
              <ul className="list-unstyled">
                <li><Link to="/login" className="text-white">Login</Link></li>
                <li><Link to="/partner-werden" className="text-white">Partner werden</Link></li>
              </ul>
            </div>
            <div className="col-4 mt-3 col-md-2 mt-md-0 text-white border-left border-info offset-md-1">
              <h3>Information</h3>
              <ul className="list-unstyled">
                <li><Link to="/datenschutz" className="text-white">Datenschutz</Link></li>
                <li><Link to="/impressum" className="text-white">Impressum</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;