import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Ueber24hBetreuung extends Component {

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section className="container content mt-5">

          <h1 className="col-12 text-center text-primary">Über 24h Betreuung</h1>

          <h2 className="mt-5">Was ist 24 Stunden Betreuung</h2>
          <p>
            Im Rahmen einer 24-Std-Pflege lebt die betreuende Hilfskraft üblicherweise im Haus oder in der Wohnung zusammen mit dem Pflegedürftigen. 
            Die Bezeichnung "24 Stunden Pflege" bedeutet allerdings nicht, dass die Betreuer/-innen oder Haushaltshilfen 24 Stunden am Stück arbeiten. 
            Hier gibt es gesetzlich festgeschriebene Arbeitszeitregelungen, welche unbedingt einzuhalten sind. Hier muss z.B. alle 6 Stunden eine 
            30 Minütigen Pause gewährleistet werden. Die Begriffe "24 Stunden Pflege" und "24 Stunden Betreuung" sind reine Marketingbezeichnungen.
          </p>

          <h2 className="mt-5">Modelle der 24 Stunden Betreuung</h2>
          <p>
            Deutsche Senioren, die von osteuropäischen Betreuungskräften gepflegt werden, sollten auf legale Bedingungen achten, da sonst hohe 
            Kosten auf Sie zukommen. Von uns erhalten Sie relevante Informationen.
          </p>

          <h3 className="mt-4">1. Entsendung</h3>
          <p>
            Entsendeunternehmen müssen eine Vielzahl von rechtlichen Voraussetzungen beachten, um ihre Arbeitnehmer legal und ordnungsgemäß 
            nach Deutschland entsenden zu dürfen.
          </p>
          <p>
            Die entsendeten Mitarbeiter sind im jeweiligen Heimatland sozialversicherungspflichtig angestellt. Sie besitzen im 
            Entsendeunternehmen alle Rechte und Pflichten eines Arbeitnehmers. Nach Beendigung des Pflegeauftrags kehren sie wieder 
            in Ihre Heimatländer zurück. Das Entsendeunternehmen zahlt sowohl Sozial- , als auch Renten- und Krankenversicherungsbeiträge 
            im Entsendeland. Als Nachweis der legalen Entsendung der Betreuungskraft nach Deutschland dient das A1- Formular.
          </p>
          <p>
            Familien, die eine entsendete Betreuungskraft bei sich beschäftigen möchten, sollten sich die A1-Bescheinigung im Original 
            vorgelegt lassen.
          </p>
          <p>
            Weitere Voraussetzung der legalen Entsendung ist, dass das Weisungsrecht des ausländischen Arbeitgebers während des gesamten 
            Entsendezeitraums fortbesteht.
          </p>
          <p>
            Erwartungen und Ziele der Arbeit jeder Betreuungskraft sollten daher möglichst umfassend und Präzise vorab besprochen werden, 
            da die deutschen Familien bei der täglichen Umsetzung der Aufgaben nicht direkt Weisungsbefugt sind.
          </p>
          <p>
            Des Weiteren muss geklärt werden, ob die Betreuungskraft für die Familie überwiegend grundpflegerische Tätigkeiten erbringen soll. 
            Ist dies der Fall, gilt der deutsche Mindestlohn der Pflege. An diesen Mindestlohn sind nicht nur Pflegeunternehmen aus Deutschland 
            gebunden, sondern auch alle im Ausland ansässigen, welche Ihre Pflegekräfte nach Deutschland entsenden.
          </p>
          <p>
            Aufgaben der 24 Stunden Betreuungskraft sind:<br/>
            Grundpflege: Hilfe bei der Körperpflege, Toilettengängen, Inkontinenzversorgung und Lagewechsel<br/>
            <br/>
            Hauswirtschaft: Kochen, Putzen, Wäsche waschen und Einkaufen<br/>
            <br/>
            Alltagsbegleitung: Begleitung zu Familienfesten, Kulturveranstaltungen und Arztbesuchen<br/>
            <br/>
            Demenzbetreuung: Aktivierung, Strukturierung des Tagesablaufs, für Sicherheit sorgen
          </p>

          <h3 className="mt-4">2. Eigenanstellung</h3>
          <p>
            Jede pflegebedürftige Person kann selbst als Arbeitgeber fungieren und eine Betreuungskraft beschäftigen. Dies ist eine der 
            Alternativen zur Beauftragung einer selbständigen Betreuungskraft oder der Entsendung. Hier sind viel Zeit und Energie vonnöten. 
            Unterstützung finden Familien beim Rechtsanwalt oder Steuerberater.
          </p>
          <p>
            Wichtig - Die Erledigung der Formalitäten könnte ein bis zwei Monate in Anspruch nehmen.
          </p>
          <p>
            Neben der Prüfung der Aufenthaltserlaubnis für die Betreuungskraft, ist anzuraten einen schriftlichen Arbeitsvertrag 
            (§ 2 Abs.1 des Nachweisgesetzes) abzuschließen.
          </p>
          <p>
            Der Betreuungskraft steht der in Deutschland gesetzlich festgelegte Mindestlohn zu. Privatpersonen, welche Pflegekräfte direkt 
            beschäftigen sind verpflichtet, diese bei der gesetzlichen Sozialversicherung anmelden. Diese umfasst Kranken-, Pflege-, 
            Renten- und Arbeitslosenversicherung sowie die Unfallversicherung. Ob Lohnsteuer abzuführen ist, ebenfalls zu prüfen. 
            Außerdem ist das Arbeitszeitgesetz einzuhalten, so ist eine Betreuung „24/7“ nicht möglich. Lohnfortzahlung im Krankheitsfall 
            und bezahlter Urlaub sind ebenfalls Pflicht.
          </p>
          <p>
            Wer widersetzlich handelt, begeht eine Straftat. Diese wird mit Freiheitsstrafen von bis zu fünf Jahren oder einer Geldstrafe 
            geahndet.
          </p>

          <h3 className="mt-4">3. Beschäftigung selbständiger Betreuungskräfte</h3>
          <p>
            In diesem Fall schließt der Kunde einen Vertrag mit einer Betreuungskraft ab, welche ein Gewerbe angemeldet hat. 
            Auch diese Betreuungskraft wohnt mit im Haushalt, ist aber formal "selbständig", sodass der Auftraggeber keine 
            Sozialversicherungsbeiträge gezahlt werden müssen. Auch bei diesem Modell besteht formal kein Weisungsrecht gegenüber der 
            Betreuungskraft. Die Aufgaben Betreuungskraft ergeben sich aus dem erteilten Auftrag. Vorsicht - hier besteht ein hohes 
            Risiko für eine Scheinselbständigkeit, die für die Pflegebedürftigen nachträglich zu hohen Bußgeldern und Nachzahlungen zur 
            Sozialversicherung führen kann.
          </p>

          <h2 className="mt-5">Aufgaben der 24- Stunden Bertreuerin</h2>
          <p>
            Zu den Leistungen der 24-Stunden-Pflege bzw. 24-Stunden-Betreuung zählen viele verschiedene Bereiche, u. a.:
          </p>
          <ul>
            <li>Grundpflege</li>
            <li>Hauswirtschaftliche Versorgung</li>
            <li>Gesellschaft leisten und Unterhaltung</li>
            <li>Aktivierung und Bewegungsförderung</li>
          </ul>

          <h2 className="mt-5">Finanzierung der 24 Stunden Betreuung</h2>
          <p>
            Durchschnittlich liegen die monatlichen Kosten der 24-Stunden-Pflege durch Osteuropäisches Personal zwischen 2.100 und 5.000 Euro. 
            Der Preis variiert im Bezug auf den Deutschkenntnissen, Qualifikation und Berufserfahrung der Betreuungskraft, ob ein 
            Führerschein benötigt wird und Nachtarbeit geleistet werden muss.
          </p>
          <p>
            Bei der Betreuung durch deutsches Pflegepersonal fällt mindestens das doppelte an.
          </p>
          <p>
            Die Summe, welche die zu betreuende Person selbst zahlen muss, berechnet sich aus dem Gehalt der Pflegekraft abzüglich 
            eventuell zustehenden Pflegegeldes, Leistungen für Verhinderungspflege sowie des zu zahlenden Steuervorteils.
          </p>

          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col">Leistungen</th>
                <th scope="col">Pflegegrad 1</th>
                <th scope="col">Pflegegrad 2</th>
                <th scope="col">Pflegegrad 3</th>
                <th scope="col">Pflegegrad 4</th>
                <th scope="col">Pflegegrad 5</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pflegesachleistung, häusliche Pflege</td>
                <td>-</td>
                <td>689 EUR</td>
                <td>1.298 EUR</td>
                <td>1.612 EUR</td>
                <td>1.995 EUR</td>
              </tr>
              <tr>
                <td>Teilstationäre Pflege</td>
                <td>-</td>
                <td>689 EUR</td>
                <td>1.298 EUR</td>
                <td>1.612 EUR</td>
                <td>1.995 EUR</td>
              </tr>
              <tr>
                <td>Vollstationäre Pflegeleistungen</td>
                <td>125 EUR<br/>Zuschuss</td>
                <td>770 EUR</td>
                <td>1.262 EUR</td>
                <td>1.775 EUR</td>
                <td>2.005 EUR</td>
              </tr>
              <tr>
                <td>Entlastungsbetrag, nach Vorlage der entsprechenden Belege</td>
                <td>125 EUR</td>
                <td>125 EUR</td>
                <td>125 EUR</td>
                <td>125 EUR</td>
                <td>125 EUR</td>
              </tr>
              <tr>
                <td>Kurzzeitpflege, pro Kalenderjahr</td>
                <td>-</td>
                <td>1.612 EUR</td>
                <td>1.612 EUR</td>
                <td>1.612 EUR</td>
                <td>1.612 EUR</td>
              </tr>
              <tr>
                <td>Verhinderungspflege, pro Kalenderjahr</td>
                <td>-</td>
                <td>1.612 EUR</td>
                <td>1.612 EUR</td>
                <td>1.612 EUR</td>
                <td>1.612 EUR</td>
              </tr>
              <tr>
                <td>zum Verbrauch bestimmte Pflegehilfsmittel</td>
                <td>40 EUR</td>
                <td>40 EUR</td>
                <td>40 EUR</td>
                <td>40 EUR</td>
                <td>40 EUR</td>
              </tr>
              <tr>
                <td>Wohnumfeldverbesserung, pro Maßnahme</td>
                <td>4.000 EUR</td>
                <td>4.000 EUR</td>
                <td>4.000 EUR</td>
                <td>4.000 EUR</td>
                <td>4.000 EUR</td>
              </tr>
              <tr>
                <td>Wohngruppenzuschlag</td>
                <td>214 EUR</td>
                <td>214 EUR</td>
                <td>214 EUR</td>
                <td>214 EUR</td>
                <td>214 EUR</td>
              </tr>
            </tbody>
          </table>

          <p>
            Wichtig zu Wissen - Die Kosten für eine 24-Stunden-Betreuung können mit bis zu 
            4.000 Euro im Jahr als <a href="https://www.vlh.de/wohnen-vermieten/eigentum/haushaltsnahe-dienstleistungen-so-sparen-sie-steuern.html" target="_blank" rel="noopener noreferrer">haushaltsnahe 
            Dienstleistungen</a> bei der Einkommenssteuer geltend gemacht werden.
          </p>
          
          
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Ueber24hBetreuung;
