import React from 'react';
import {createRoot} from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import Routes from './routes';

import './custom.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Routes />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();