import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Ratgeber extends Component {

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section className="container content mt-5">
          <i className="d-block material-icons text-center icon-50 mb-3">verified_user</i>
          <h1 className="col-12 text-center">Ratgeber</h1>
          <p>Ratgeber</p>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Ratgeber;
