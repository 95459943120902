import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import App from './App';
import BetreuungAnfordern from './pages/BetreuungAnfordern/BetreuungAnfordern';
import Datenschutz from './pages/Datenschutz/Datenschutz';
import Impressum from './pages/Impressum/Impressum';
import Kontakt from './pages/Kontakt/Kontakt';
import Login from './pages/Login/Login';
import PartnerWerden from './pages/PartnerWerden/PartnerWerden';
import Ratgeber from './pages/Ratgeber/Ratgeber';
import Ueber24hBetreuung from './pages/Ueber24hBetreuung/Ueber24hBetreuung';
import UeberSENIOinfo24 from './pages/UeberSENIOinfo24/UeberSENIOinfo24';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={App} />
      <Route path="/betreuung-anfordern" component={BetreuungAnfordern} />
      <Route path="/datenschutz" component={Datenschutz} />
      <Route path="/impressum" component={Impressum} />
      <Route path="/kontakt" component={Kontakt} />
      <Route path="/login" component={Login} />
      <Route path="/partner-werden" component={PartnerWerden} />
      <Route path="/ratgeber" component={Ratgeber} />
      <Route path="/ueber-24-betreuung" component={Ueber24hBetreuung} />
      <Route path="/ueber-senioinfo24" component={UeberSENIOinfo24} />
      <Route component={App} />
    </Switch>
  </Router>
);

export default Routes;