import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class BetreuungAnfordern extends Component {

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section className="container content mt-5">
          
          <h1 className="col-12 text-center text-primary">Jetzt passende Betreuung anfordern</h1>
          <p className="col-12 text-center text-primary">Bitte füllen Sie das unten stehende Formular aus, damit wir Ihnen ein passgenaues Angebot unterbreiten können.</p>

          <iframe title="SENIOinfo24 Suche" className="border-0" src="https://widget.senioinfo24.de" style={{width: '100%', height: '650px'}} />

        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default BetreuungAnfordern;
