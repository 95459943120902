import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class Header extends Component {

  openMobileNavigation = () => {
    document.querySelector('#mobile-navigation').classList.add('d-block');
  }

  closeMobileNavigation = () => {
    document.querySelector('#mobile-navigation').classList.remove('d-block');
  }

  render() {
    return (
      <>
      <div className="bg-primary" id="mobile-navigation">
        <div className="text-right mt-3 mr-3 mt-md-4 mr-md-5">
          <div className="material-icons md-48 md-white cursor" onClick={this.closeMobileNavigation}>close</div>
        </div>
        <h2 className="text-white ml-4 text-uppercase">Navigation</h2>
        <div className="border-bottom border-light mx-3" />
        <ul className="mt-3 text-white">
          <li className="py-2"><Link to="/" className="text-white">Startseite</Link></li>
          <li className="py-2"><Link to="/ueber-senioinfo24" className="text-white">Über SENIOinfo24</Link></li>
          <li className="py-2"><Link to="/ueber-24-betreuung" className="text-white">Über 24h Betreuung</Link></li>
          <li className="py-2"><Link to="/betreuung-anfordern" className="text-white shadow-sm btn bg-secondary">Betreuung anfordern</Link></li>
        </ul>
        <h2 className="text-white mt-3 ml-4 text-uppercase">Portal</h2>
        <div className="border-bottom border-light mx-3" />
        <ul className="mt-3 text-white">
          <li className="py-2"><Link to="/login" className="text-white">Login</Link></li>
          <li className="py-2"><Link to="/partner-werden" className="text-white">Partner werden</Link></li>
        </ul>
        <h2 className="text-white mt-3 ml-4 text-uppercase">Information</h2>
        <div className="border-bottom border-light mx-3" />
        <ul className="mt-3 text-white">
          <li className="py-2"><Link to="/datenschutz" className="text-white">Datenschutz</Link></li>
          <li className="py-2"><Link to="/impressum" className="text-white">Impressum</Link></li>
        </ul>
      </div>
      <div className="container-fluid header border-bottom sticky-top bg-white">
        <div className="container pt-3 pb-3">
          <div className="row">
            <div className="col-9 col-lg-3">
              <Link to="/" className="mr-3">
                <img className="mb-4 img-fluid" src="/images/senioinfo24-logo-280x104.png" alt="SENIOinfo24 Logo" />
              </Link>
            </div>
            <div className="col-3 my-auto text-right d-lg-none">
              <i className="material-icons md-48 cursor" onClick={this.openMobileNavigation}>menu</i>
            </div>
            <nav className="col-12 my-auto text-right d-none col-lg-9 d-lg-block">
              <Link to="/" className="mr-3">Startseite</Link>
              <Link to="/ueber-senioinfo24" className="mr-3">Über SENIOinfo24</Link>
              <Link to="/ueber-24-betreuung" className="mr-3">Über 24h Betreuung</Link>
              <Link to="/betreuung-anfordern" className="text-white shadow-sm btn bg-secondary">Betreuung anfordern &rarr;</Link>
            </nav>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withRouter(Header);
