import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class PartnerWerden extends Component {

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section className="container mt-5 text-center">

          <i className="material-icons text-center md-48">group_add</i>
          <h1 className="text-primary">Partner werden</h1>

          <p>Sie wollen Partner werden? <br/>Dann schreiben Sie uns eine E-Mail an <a href="mailto:kontakt@senioinfo24.de">kontakt@senioinfo24.de</a></p>

        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default PartnerWerden;
