import React from 'react';
import { Link } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

const App = () => {

  return (
    <div className="app">
      <Header />
      <div className="container-fluid container-xl bg-zwei-senioren-frauen mb-5 pb-5">
        <div className="row justify-content-md-start">
          <div className="col-12 text-center col-md-8 text-md-left col-lg-6 col-xl-5">
            <h1 className="mt-2 mt-md-5 mb-md-4 ml-md-2 text-primary">24 Stunden Betreuung in Ihrer Umgebung finden</h1>
            <Link to="/betreuung-anfordern" className="col-12 col-md-7 text-white shadow-sm btn bg-secondary">Betreuung anfordern &rarr;</Link>
          </div>
        </div>
      </div>
      <section className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <i className="d-block material-icons md-48 text-center">supervisor_account</i>
            <h2 className="text-center">
              Zuverlässige 24 Stunden Betreuung - wir
              helfen Ihnen bei der optimalen Versorgung
            </h2>
            <p className="mt-3 text-justify">
              Wir bieten Ihnen umfassende Hilfe zum Thema Pflege und Betreuung Ihrer
              Angehörigen. Egal ob es sich um ein paar Stunden in der Woche handelt oder ob
              Sie eine tägliche 24 Stunden Betreuung benötigen. Uns sind der persönliche Kontakt
              und die individuelle Beratung bei Ihnen vor Ort sehr wichtig. Jede Situation bringt
              andere Bedürfnisse mit sich, auf die wir uns einstellen um Ihnen eine passende
              Pflegekraft zur Verfügung zu stellen.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid text-center mt-4">
        <img src="/images/zuverlaessige-24-stunden-betreuung.jpg" alt="image2" className="img-fluid rounded shadow-sm" />
      </section>

      <div className="container my-5">
        <h2 className="text-center">Gemeinsam sind wir stark</h2>
      </div>

      <div className="container-fluid container-xl bg-light pt-4 p-xl-4">
        <div className="row justify-content-center justify-content-xl-start">
          <div className="col-10 d-none d-md-block col-xl-5 text-center">
            <img src="/images/vertraute-umgebung.jpg" alt="Frau hält sitzenden Mann im Rollstuhl an den Schultern und sie lächeln sich dabei an" className="img-fluid shadow-sm rounded" />
          </div>
          <div className="col-10 col-xl-7">
            <div className="row justify-content-center justify-content-xl-start">
              <div className="col-12 mt-3 mt-xl-0">
                <i className="d-block text-center text-xl-left material-icons md-48">schedule</i>
              </div>
              <div className="col-12 mt-3">
                <h2 className="text-center text-xl-left">24 Stunden Betreuung - Rund um die Uhr in vertrauter Umgebung</h2>
              </div>
              <div className="col-12">
                <p className="mt-3 mt-xl-1 text-justify">
                  Das selbstständige Leben in den eigenen vier Wänden kann im Alter zunehmend
                  schwieriger werden, vor allem wenn noch gesundheitliche Einschränkungen
                  hinzukommen. SENIOInfo24 unterstützt Senioren dabei, weiterhin Zuhause im
                  Gewohnten Umfeld leben zu können.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid container-xl bg-light pt-4 mt-xl-4 p-xl-4">
        <div className="row justify-content-center justify-content-xl-start">
          <div className="col-10 d-none d-md-block col-xl-5 order-xl-last text-center">
            <img src="/images/haende-halten-zusammenhalt.jpg" alt="Frau hält sitzenden Mann im Rollstuhl an den Schultern und sie lächeln sich dabei an" className="img-fluid shadow-sm rounded" />
          </div>
          <div className="col-10 col-xl-7 order-xl-first">
            <div className="row justify-content-center justify-content-xl-start">
              <div className="col-12 mt-3 mt-xl-0">
                <i className="d-block text-center text-xl-left material-icons md-48 text-danger">favorite</i>
              </div>
              <div className="col-12 mt-3">
                <h2 className="text-center text-xl-left">Professionell und mit Herz – die Betreuerinnen begleiten durch den Alltag</h2>
              </div>
              <div className="col-12">
                <p className="mt-3 mt-xl-1 text-justify">
                  Dazu gehören unter anderem die Hilfe bei der täglichen Körperpflege, aktivierende
                  Betreuung wie z.B. Vorlesen, Gesellschaftsspiele, kleine Spaziergänge oder einfach
                  nur zuhören und da sein. Hauswirtschaftliche Tätigkeiten und das erledigen der
                  Einkäufe sowie die Begleitung bei Arztbesuchen gehören ebenfalls zu den Aufgaben
                  einer 24 Stunden Betreuung. Auch in der Nacht ist Ihr Angehöriger nicht alleine und
                  wird betreut.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>        
      <Footer />
    </div>
  );
}

export default App;