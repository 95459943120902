import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Login extends Component {

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <section className="container text-center mt-5">
          <i className="material-icons text-center md-48">vpn_key</i>
          <h1 className="text-primary">Login</h1>
        </section>

        <div className="container text-center mt-4">

          <div className="row justify-content-center">
            <div className="col-5">
              <input className="form-control" type="text" placeholder="E-Mail" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5">
              <input className="form-control mt-2" type="password" placeholder="Passwort" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5">
              <input className="form-control btn-primary mt-2" type="submit" placeholder="Login" />
            </div>
          </div>
        
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Login;
